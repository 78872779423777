.Backdrop {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
}

.Container {
  display: flex;
  height: 90%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 14px;
  margin: 0 auto;
  width: 95%;
}

.TitleWrapper h2 {
  line-height: 37px;
  font-weight: 600;
  color: #3D3D3D;
  text-align: center;
  /* font-size: 34px; */
  font-size: 1.8em;
  margin-bottom: 10px;
}

.TitleWrapper h3 {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.YtIframe {
  height: 140px;
  width: 100%;

  border-radius: 10px;
}

.Wrapper {
  background-color: white;
  padding: 30px;
  border-radius: 8px;

  width: 90%;
  max-width: 700px;
}

.progressContainer {
  width: 80%;
  height: 3px;
  margin: 0 auto;
  background-color: #555;
  margin-top: 10px;
}

.progressBar {
  height: 100%;
  background-color: red;
  transition: width 0.1s linear;
}

.TextInfo {
  font-size: 12px;
  text-align: center;
  margin-top: 8px;
}

.ButtonsWrapper {
  margin: 0 auto;
  margin-top: 12px;

  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  display: flex;
  max-width: 435px;
}

.CTAButton {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 230px;
  width: 200px;
  height: 50px;
  padding: 20px;
  border-radius: 9px;
  color: white;
  font-size: 18px;
  background-color: #8d5dc6;
  font-weight: 800;
  cursor: pointer;
}

.CTAButton:disabled {
  cursor: not-allowed;
  background-color: rgba(141, 93, 198, 0.49);
}

@media (min-width: 400px) {
  .YtIframe {
    height: 150px;
  }
}

@media (min-width: 430px) {
  .YtIframe {
    height: 190px;
  }
}

@media (min-width: 525px) {
  .YtIframe {
    height: 210px;
  }
}

@media (min-width: 610px) {
  .YtIframe {
    height: 240px;
  }
}

@media (min-width: 720px) {
  .YtIframe {
    height: 290px;
  }
}

@media (min-width: 840px) {
  .YtIframe {
    height: 360px;
  }
}
